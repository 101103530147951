<template>
    <li class="staff__item staff-rec">
        <div class="staff-rec__user">
            <img
                :src="data.image_zoom_out || defaultAvatar"
                class="staff-rec__user-img"
                @error="errorAvatar"
            >
            <div class="staff-rec__user-wrapper">
                <router-link tag="a" :to="`/auth/profile/${data.id}/?staff=1`" class="staff-rec__user-name">
                    {{data.full_name}}
                </router-link>
                <div class="staff-rec__user-position" v-if="data.positions">
                    {{data.positions.name}}
                </div>
            </div>
        </div>
        <div class="staff-rec__contact">
            <div class="staff-rec__mail">
                <div class="staff-rec__mail-visible">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" width="16" height="16">
                        <path
                            d="M504.888 111.933L264.994 297.997c-2.666 2.007-5.83 3.003-8.994 3.003s-6.328-.996-8.994-3.003L7.112 111.933C2.664 118.909 0 127.13 0 136v240c0 24.814 20.186 45 45 45h422c24.814 0 45-20.186 45-45V136c0-8.87-2.664-17.091-7.112-24.067z"></path>
                        <path
                            d="M467 91H45c-4.618 0-8.987.901-13.184 2.199L256 267.25 480.184 93.199C475.987 91.901 471.618 91 467 91z"></path>
                    </svg>
                    {{data.email}}
                </div>
                <div
                    class="staff-rec__mail-hidden"
                    v-clipboard:copy="data.email"
                    v-clipboard:success="onCopy"
                >
                    <svg width="12" height="14" viewBox="0 0 12 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M7.54524 14H2.22738C0.999166 14 0 13.0187 0 11.8125V4.40234C0 3.19612 0.999166 2.21484 2.22738 2.21484H7.54524C8.77346 2.21484 9.77262 3.19612 9.77262 4.40234V11.8125C9.77262 13.0187 8.77346 14 7.54524 14ZM2.22738 3.30859C1.61333 3.30859 1.11369 3.79929 1.11369 4.40234V11.8125C1.11369 12.4156 1.61333 12.9062 2.22738 12.9062H7.54524C8.1593 12.9062 8.65893 12.4156 8.65893 11.8125V4.40234C8.65893 3.79929 8.1593 3.30859 7.54524 3.30859H2.22738ZM12 10.4453V2.1875C12 0.981277 11.0008 0 9.77262 0H3.59165C3.28408 0 3.0348 0.244812 3.0348 0.546875C3.0348 0.848938 3.28408 1.09375 3.59165 1.09375H9.77262C10.3867 1.09375 10.8863 1.58444 10.8863 2.1875V10.4453C10.8863 10.7474 11.1356 10.9922 11.4432 10.9922C11.7507 10.9922 12 10.7474 12 10.4453Z" fill="#CCCCCC"></path>
                    </svg>
                    {{data.email}}
                </div>
            </div>
            <div class="staff-rec__phone" v-if="data.phone_number">
                <svg x="0px" y="0px" width="13px" height="13px" viewBox="0 0 401.998 401.998">
                    <path d="M401.129,311.475c-1.137-3.426-8.371-8.473-21.697-15.129c-3.61-2.098-8.754-4.949-15.41-8.566
                                c-6.662-3.617-12.709-6.95-18.13-9.996c-5.432-3.045-10.521-5.995-15.276-8.846c-0.76-0.571-3.139-2.234-7.136-5
                                c-4.001-2.758-7.375-4.805-10.14-6.14c-2.759-1.327-5.473-1.995-8.138-1.995c-3.806,0-8.56,2.714-14.268,8.135
                                c-5.708,5.428-10.944,11.324-15.7,17.706c-4.757,6.379-9.802,12.275-15.126,17.7c-5.332,5.427-9.713,8.138-13.135,8.138
                                c-1.718,0-3.86-0.479-6.427-1.424c-2.566-0.951-4.518-1.766-5.858-2.423c-1.328-0.671-3.607-1.999-6.845-4.004
                                c-3.244-1.999-5.048-3.094-5.428-3.285c-26.075-14.469-48.438-31.029-67.093-49.676c-18.649-18.658-35.211-41.019-49.676-67.097
                                c-0.19-0.381-1.287-2.19-3.284-5.424c-2-3.237-3.333-5.518-3.999-6.854c-0.666-1.331-1.475-3.283-2.425-5.852
                                s-1.427-4.709-1.427-6.424c0-3.424,2.713-7.804,8.138-13.134c5.424-5.327,11.326-10.373,17.7-15.128
                                c6.379-4.755,12.275-9.991,17.701-15.699c5.424-5.711,8.136-10.467,8.136-14.273c0-2.663-0.666-5.378-1.997-8.137
                                c-1.332-2.765-3.378-6.139-6.139-10.138c-2.762-3.997-4.427-6.374-4.999-7.139c-2.852-4.755-5.799-9.846-8.848-15.271
                                c-3.049-5.424-6.377-11.47-9.995-18.131c-3.615-6.658-6.468-11.799-8.564-15.415C98.986,9.233,93.943,1.997,90.516,0.859
                                C89.183,0.288,87.183,0,84.521,0c-5.142,0-11.85,0.95-20.129,2.856c-8.282,1.903-14.799,3.899-19.558,5.996
                                c-9.517,3.995-19.604,15.605-30.264,34.826C4.863,61.566,0.01,79.271,0.01,96.78c0,5.135,0.333,10.131,0.999,14.989
                                c0.666,4.853,1.856,10.326,3.571,16.418c1.712,6.09,3.093,10.614,4.137,13.56c1.045,2.948,2.996,8.229,5.852,15.845
                                c2.852,7.614,4.567,12.275,5.138,13.988c6.661,18.654,14.56,35.307,23.695,49.964c15.03,24.362,35.541,49.539,61.521,75.521
                                c25.981,25.98,51.153,46.49,75.517,61.526c14.655,9.134,31.314,17.032,49.965,23.698c1.714,0.568,6.375,2.279,13.986,5.141
                                c7.614,2.854,12.897,4.805,15.845,5.852c2.949,1.048,7.474,2.43,13.559,4.145c6.098,1.715,11.566,2.905,16.419,3.576
                                c4.856,0.657,9.853,0.996,14.989,0.996c17.508,0,35.214-4.856,53.105-14.562c19.219-10.656,30.826-20.745,34.823-30.269
                                c2.102-4.754,4.093-11.273,5.996-19.555c1.909-8.278,2.857-14.985,2.857-20.126C401.99,314.814,401.703,312.819,401.129,311.475z"></path>
                </svg>
                {{data.phone_number}}
            </div>
            <div class="staff-rec__phone hidden"></div>
        </div>
        <div class="staff-rec__department">
            <span v-for="struct in data.structure">{{struct.name}} <br></span>
        </div>
        <div class="staff-rec__active" :class="{'staff-rec__active_false':!data.is_active}">
            {{data.is_active ? 'Работает' : 'Уволен'}}
        </div>
        <div class="btn-menu-wrap staff-rec__menu-btn" v-if="current_user.is_admin">
            <v-popover
                offset="1"
                trigger="click"
                placement="bottom-end"
                popoverInnerclass="v-popover__body"
                class="v-popover"
            >
                <button class="btn-menu-open"></button>

                <template slot="popover" class="v-popover__content">
                    <ul class="v-popover__links">
                        <li class="v-popover__item">
                            <i class="v-popover__icon--staff-edit"/>
                            <a
                                href="javascript:void(0)"
                                @click="openModal(data)"
                                v-close-popover
                            >Редактировать</a>
                        </li>

                        <li class="v-popover__item">
                            <i class="v-popover__icon--staff-password"/>
                            <a
                                href="javascript:void(0)"
                                @click="sendPassword(data)"
                                v-close-popover
                            >Отправить пароль</a>
                        </li>

                        <li class="v-popover__item" v-if="data.id !== current_user.id">
                            <i class="v-popover__icon--staff-remove"/>
                            <a
                                href="javascript:void(0)"
                                @click="deleteUser(data)"
                                v-close-popover
                            >Удалить</a>
                        </li>
                    </ul>
                </template>
            </v-popover>
            <div class="btn-menu-inner btn-menu-inner-off">
                <div class="triangle-shadow"></div>
                <button title="Редактировать" class="btn-menu-one staff-rec__edit" @click="()=>openModal(data)">
                    <svg width="14" height="15" viewBox="0 0 14 15">
                        <path d="M3.413 13.711L.91 11.207 11.449.666l2.505 2.504zm-1.028.894H.015v-2.37z"></path>
                    </svg>
                    Редактировать
                </button>
                <button name="lawbreak" title="Отправить пароль"
                        class="btn-menu-one staff-rec__send-key" @click="sendPassword(data)">
                    <svg x="0px" y="0px" width="32px" height="32px" viewBox="0 0 516.375 516.375">
                        <path d="M353.812,0C263.925,0,191.25,72.675,191.25,162.562c0,19.125,3.825,38.25,9.562,57.375L0,420.75v95.625h95.625V459H153
                                    v-57.375h57.375l86.062-86.062c17.213,5.737,36.338,9.562,57.375,9.562c89.888,0,162.562-72.675,162.562-162.562S443.7,0,353.812,0
                                    z M401.625,172.125c-32.513,0-57.375-24.862-57.375-57.375s24.862-57.375,57.375-57.375S459,82.237,459,114.75
                                    S434.138,172.125,401.625,172.125z"></path>
                    </svg>
                    Отправить пароль
                </button>
                <button name="lawbreak" title="Удалить" v-if="data.id !== current_user.id"
                        class="btn-menu-one staff-rec__dell" @click="deleteUser(data)">
                    <svg xmlns="http://www.w3.org/2000/svg" width="47.971" height="47.971" viewBox="0 0 47.971 47.971">
                        <path
                            d="M28.228 23.986L47.092 5.122a2.998 2.998 0 000-4.242 2.998 2.998 0 00-4.242 0L23.986 19.744 5.121.88a2.998 2.998 0 00-4.242 0 2.998 2.998 0 000 4.242l18.865 18.864L.879 42.85a2.998 2.998 0 104.242 4.241l18.865-18.864L42.85 47.091c.586.586 1.354.879 2.121.879s1.535-.293 2.121-.879a2.998 2.998 0 000-4.242L28.228 23.986z"></path>
                    </svg>
                    Удалить
                </button>
            </div>
        </div>
    </li>
</template>

<script>
    import session from '@/api/session';
    import {mapState} from "vuex";
    import defaultAvatar from 'assets/img/avatars/default_avatar.jpg';

    export default {
        name: "StaffListItem",
        data() {
            return {
                defaultAvatar
            }
        },
        props: {
            data: {
                type: Object,
                required: true
            }
        },
        methods: {
            async sendPassword(user) {
                const isBlocked = this.isBlockedPortal();

                if (!isBlocked) {
                    this.$swal({
                        customClass: {
                            confirmButton: 'btn btn-lg btn-alt-success m-5',
                            cancelButton: 'btn btn-lg btn-alt-danger m-5'
                        },
                        title: 'Отправить новый пароль пользователю?',
                        text: user.full_name,
                        icon: 'question',
                        showCancelButton: true,
                        confirmButtonText: 'Да, отправить!',
                        cancelButtonText: 'Отменить',
                    }).then(async (value) => {
                        if (value.value) {
                            let swal = this.$swal({
                                title: 'Отправка...',
                                icon: 'info',
                                showConfirmButton: false,
                                allowOutsideClick: false,
                                html: '<i class="fa fa-2x fa-cog fa-spin"></i>'
                            });
                            try {
                                await session.post(`/api/v1/staff/${user.id}/send_new_password/ `);
                                swal.close();
                                this.$swal({
                                    title: 'Отлично!',
                                    text: 'Новый пароль отправлен на почту пользователя.',
                                    icon: 'success',
                                    showConfirmButton: false,
                                    timer: 1400,
                                });
                            } catch (err) {
                                console.error(err)
                                swal.close();
                                this.$swal({
                                    title: 'Ошибка!',
                                    text: 'При обработке запроса произошла ошибка на сервере',
                                    icon: 'error',
                                    showConfirmButton: false,
                                    timer: 1400,
                                });
                            }
                        }
                    })
                }
            },
            async deleteUser(user) {
                const isBlocked = this.isBlockedPortal();

                if (!isBlocked) {
                    this.$swal({
                        customClass: {
                            confirmButton: 'btn btn-lg btn-alt-success m-5',
                            cancelButton: 'btn btn-lg btn-alt-danger m-5'
                        },
                        title: 'Удалить пользователя?',
                        text: user.full_name,
                        icon: 'warning',
                        showCancelButton: true,
                        confirmButtonText: 'Да, удалить!',
                        cancelButtonText: 'Отменить',
                    }).then(async (value) => {
                        if (value.value) {
                            let swal = this.$swal({
                                title: 'Удаление...',
                                icon: 'info',
                                showConfirmButton: false,
                                allowOutsideClick: false,
                                html: '<i class="fa fa-2x fa-cog fa-spin"></i>'
                            });
                            try {
                                await session.delete(`/api/v1/staff/${this.data.id}/`);
                                swal.close();
                                this.$emit('removeUser', user);
                                this.$swal({
                                    title: 'Отлично!',
                                    text: 'Пользователь удален',
                                    icon: 'success',
                                    showConfirmButton: false,
                                    timer: 1400,
                                });
                            } catch (err) {
                                swal.close();
                                this.$swal({
                                    title: 'Ошибка!',
                                    text: 'При обработке запроса произошла ошибка на сервере',
                                    icon: 'error',
                                    showConfirmButton: false,
                                    timer: 1400,
                                });
                            }
                        }
                    })
                }
            },
            openModal(user) {
                const isBlocked = this.isBlockedPortal();

                if (!isBlocked) {
                    const ChangeStaffModal = () => import(`../detail/ChangeStaffModal`);
                    this.$modal.show(ChangeStaffModal, {
                        user_id: user.id
                    }, {
                        name: 'change-staff-modal',
                        scrollable: true,
                        height: 'auto',
                        adaptive: true,
                        maxWidth: 740,
                        width: '100%',
                        styles: {overflow: "inherit",},
                        clickToClose: false
                    }, {
                        'before-close': (data) => {
                            if (data.params) {
                                this.$emit('updateUser', {
                                    new_user: data.params.user,
                                    user: user
                                });
                            }
                        }
                    })
                }
            },
            onCopy() {
                this.$swal.fire({
                    toast: true,
                    position: 'top-end',
                    title: 'Эл.почта скопирована',
                    icon: "success",
                    showConfirmButton: false,
                    timer: 3000,
                    timerProgressBar: true,
                    onOpen: (toast) => {
                        toast.addEventListener('mouseenter', this.$swal.stopTimer);
                        toast.addEventListener('mouseleave', this.$swal.resumeTimer);
                    }
                });
            },
            errorAvatar(event) {
                event.target.setAttribute('src', this.defaultAvatar);
            }
        },
        computed: {
            ...mapState('default_data', [
                'current_user'
            ]),
        },
    }
</script>

<style lang="scss">
    @import "#sass/v-style";

        .v-popover {
            &__item {
                display: flex;
                align-items: center;
                padding: 0px 5px;
            }
            &__icon {
                margin-right: 5px;
                &--staff-edit {
                    @include icon($staff-edit-icon, 13px)
                }
                &--staff-password {
                    @include icon($staff-password-icon, 13px)
                }
                &--staff-remove {
                    @include icon($staff-delete-icon, 13px)
                }
            }
        }
</style>
